//@ts-nocheck
import BigNumber from "bignumber.js"

export const getPriceSale = (price, sale) => {

    if (sale === 0) {
        return price
    }

    const PriceSale = new BigNumber(price.toString()).dividedBy(new BigNumber("100")).multipliedBy(new BigNumber(sale.toString()))
    return new BigNumber(price.toString()).minus(PriceSale).decimalPlaces(0, BigNumber.ROUND_HALF_EVEN).toString()
}