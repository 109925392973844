import './ScienceButton.scss'

function ScienceButton() {
    return (
        <div className={'science_box w-100 d-flex justify-content-center align-items-center'}>
            <div className={'position-relative w-100 h-100 d-flex justify-content-center align-items-center'}>
                <div className='d-flex science_box_content justify-conetent-center align-items-center'>
                    <svg width="37" height="28" viewBox="0 0 37 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.6519 5.52966C19.8195 3.80838 23.8861 1.06043 26.9663 1.00003L32.2206 15.7061C28.5969 16.6423 22.326 19.209 18.6519 22.7723C14.9779 19.209 8.70692 16.6423 5.08325 15.7061L10.3376 1.00003C13.4177 1.06043 17.4842 3.80838 18.6519 5.52966Z" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M27.6404 2.8876L31.5561 4.11035L36.2258 17.2301C36.4552 17.8745 36.1614 18.5882 35.5448 18.8845L18.6518 27L1.75866 18.8845C1.14209 18.5882 0.848306 17.8746 1.07768 17.2301L5.74739 4.11035L9.66303 2.8876" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <div className='science_box_text'>{'Наука'}</div>
                </div>
                <div className={'small_lock'}>
                    <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.15217 15.5378C8.81044 15.5378 9.34408 15.0319 9.34408 14.4077C9.34408 13.7836 8.81044 13.2776 8.15217 13.2776C7.49389 13.2776 6.96025 13.7836 6.96025 14.4077C6.96025 15.0319 7.49389 15.5378 8.15217 15.5378Z" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M8.15207 14.4075V16.6676" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M17.6876 7.78472V5.0271" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M17.6874 7.78467L15.9313 9.8492" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M15.1447 6.27026L17.6876 7.78467" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M17.6876 7.78467L19.4437 9.8492" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M20.2305 6.27026L17.6876 7.78467" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M24.5428 7.75762V5" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M24.5426 7.75757L22.7864 9.8221" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M21.9998 6.24316L24.5427 7.75757" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M24.5428 7.75757L26.2989 9.8221" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M27.0857 6.24316L24.5428 7.75757" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M15.3039 12.9473V19.493C15.3039 20.3279 14.5951 21 13.7145 21H2.58937C1.70882 21 1 20.3279 1 19.493V11.205C1 10.3701 1.70882 9.698 2.58937 9.698H11.877" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M3.39876 5.52079C3.39876 3.02407 5.5335 1 8.16672 1C10.7999 1 12.9347 3.02407 12.9347 5.52079" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M12.9343 5.521V7.02755" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M3.39859 5.14404V7.02716" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default ScienceButton