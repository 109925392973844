//@ts-nocheck

import { numberFormat } from '../../../utils/NumberFormat'
import { getPriceSale } from '../../../utils/сhestPrice'
import ToastInfo from '../../Toasts/ToastInfo/ToastInfo'
import './ChestContainerItem.scss'

import ChestContainerItemOffcanvas from './ChestContainerItemOffcanvas/ChestContainerItemOffcanvas'

export default function ChestContainerItem({ item }) {

    const id = item.id
    const title = item.title
    const price = item.price
    const lootId = item.lootId
    const sale = item.sale
    const chestHeroes = item.chestHeroes
    const pathImage = item.pathImage

    return (
        <div onClick={() => ToastInfo("Скоро!")} className='chest-container-items d-flex justify-content-between w-100 overflow-y-visible position-relative' style={{ cursor: 'pointer' }}>
            <div className='d-flex flex-column justify-content-between'>
                <div className='chest-container-items-title'>
                    {title}
                </div>
                <div className='chest-container-items-price'>

                    {sale > 0 &&
                        <div className='d-flex align-items-center' style={{ gap: '2px' }}>
                            <div className='chest-container-items-old-price'>
                                <s>
                                    {lootId === 1 &&
                                        (numberFormat(price))
                                    }
                                    {lootId === 2 &&
                                        (price)
                                    }
                                </s>
                            </div>
                            <div className='chest-container-items-sale'>
                                -{sale}%
                            </div>
                        </div>
                    }

                    {lootId === 1 &&
                        <img style={{ marginRight: '4.5px' }} width={14.5} height={16} src='/img/loot/gold-icon.png' />
                    }
                    {lootId === 2 &&
                        <img style={{ marginRight: '5.5px' }} width={10.5} height={16} src='/img/loot/crystal-icon.png' />
                    }

                    {lootId === 1 &&
                        (numberFormat(getPriceSale(price, sale)))
                    }
                    {lootId === 2 &&
                        (getPriceSale(price, sale))
                    }
                </div>
            </div>
            <img height={'auto'} width={'auto'} className='chest-container-items-image position-absolute bottom-0 end-0' style={{ maxWidth: '90px', width:'auto', height:'auto', maxHeight:'130px' }} src={`/img/chests/x2/${pathImage}.png`} />
        </div>
    )
}