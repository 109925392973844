//@ts-nocheck

import { useSelector } from 'react-redux';
import './CoinEnergyBarInfo.scss'
import { numberFormat } from '../../../utils/NumberFormat';

export default function CoinEnergyBarInfo() {

    const userEnergyCount = useSelector((state) => state.userEnergyCount.count);

    return (
        <div className='coin-energy-bar-info d-flex flex-column align-items-start justify-content-center'>
            
            <div className='coin-energy-bar-info-text'>Энергия - восполняемый ресурс.</div>
            <div className='coin-energy-bar-info-count'>Количество: <span>{numberFormat(userEnergyCount.toString())}</span> из <span>100</span></div>
            <div className='coin-energy-bar-info-text'>Скорость восстановления: 1 в 20 секунд</div>

        </div>
    )
}
