//@ts-nocheck

import "./scss/App.scss";

import { Toaster } from 'react-hot-toast';
import { useState, useEffect } from 'react';
import StartLoading from './components/StartLoading/StartLoading';
import { startInitData } from './utils/initData';
import { Route, Routes } from 'react-router-dom';
import Heroes from './pages/Heroes/Heroes';
import Refferal from './pages/Refferal/Refferal';
import Coin from './pages/Coin/Coin';
import Header from './components/Header/Header';
import Navbar from './components/Navbar/Navbar'
import Quest from "./pages/Quest/Quest";
import { useDispatch, useSelector } from "react-redux";
import { incrementUserEnergyCount } from "./redux/userEnergyCountSlice/userEnergyCountSlice";
import { preloadImage, preloadImageWithPromise } from "./utils/preloadImage";
import Rating from "./pages/Rating/Rating";
import Error from "./pages/Error/Error";
import ApiError from "./components/ApiError/ApiError";
import Shop from "./pages/Shop/Shop";
import ApiErrorHash from "./components/ApiErrorHash/ApiErrorHash";

import socketIOClient from "socket.io-client";
import { getApiUrl, getAxiosConfig } from "./api";

export default function App() {

  //error
  const userApiError = useSelector((state) => state.userApiError.error)
  const userApiErrorHash = useSelector((state) => state.userApiErrorHash.error)

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const loadingTimeout = setTimeout(() => {

      setIsLoading(false)

      const interval = setInterval(() => {
        dispatch(incrementUserEnergyCount())
      }, 20 * 1000);
      return () => clearInterval(interval);

    }, 5000);
    return () => clearTimeout(loadingTimeout);
  }, [])

  useEffect(() => {
    const extraHeaders = getAxiosConfig().headers;

    const socket = socketIOClient(getApiUrl(), {
      extraHeaders: extraHeaders,
    });
    socket.connect()

    socket.on('userOnlineInfo', function(data){
      console.log(data.lastDate);
    });

    return () => socket.disconnect();
  }, [])

  return (
    <>
      {userApiErrorHash &&
        <ApiErrorHash />  
      }
      {userApiError &&
        <ApiError />
      }

      {(isLoading) ? (
        <StartLoading />
      ) : (
        <>
          <Routes>

            <Route path="/error" element={<Error />} />

            <Route path="/" element={<Coin />} />
            <Route path="/heroes" element={<Heroes />} />
            <Route path="/refferal" element={<Refferal />} />
            <Route path="/quest" element={<Quest />} />

            <Route path="/rating" element={<Rating />} />
            <Route path="/shop" element={<Shop />} />

          </Routes>

          {/* {!userApiError &&
            <> */}
              <Toaster />
              <Header />
              <Navbar />
            {/* </>
          } */}
        </>
      )}
    </>
  )
}
