//@ts-nocheck

import ChestContainer from '../../components/ChestContainer/ChestContainer'
import ShopHeroContainer from '../../components/ShopHeroContainer/ShopHeroContainer'

import './Shop.scss'

export default function Shop() {
  return (
    <main className='w-100 d-flex flex-column align-items-center main-background main-container'>
        <div className='shop-container w-100 h-100 d-flex flex-column overflow-auto'>

            <div className='shop-title'>Сундуки с наградой</div>
            <div className='text-white h-1' style={{marginTop: '18px'}}>
                <ChestContainer/>
            </div>

            <div className='shop-title' style={{marginTop: '10px'}}>Герои</div>
            <div className='text-white h-1' style={{marginTop: '18px'}}>
                <ShopHeroContainer/>
            </div>

        </div>
    </main>
  )
}