//@ts-nocheck

import { useQuery } from 'react-query'
import './ChestContainer.scss'
import ChestContainerItem from './ChestContainerItem/ChestContainerItem'
import { get } from '../../api/chest'
import DataFetchLoader from '../DataFetchLoader/DataFetchLoader'
import DataFetchError from '../DataFetchError/DataFetchError'

export default function ChestContainer() {

    const { data, isLoading, isError, isSuccess } = useQuery(
        ['chests'],
        () => get(),
        {
            keepPreviousData: true,
        }
    )

    return (
        <div className='chest-container d-flex flex-wrap justify-content-between' style={{ gap: '8px' }}>

            {isSuccess &&
                <>
                    {data.data.chests.length === 0 &&
                        <div className=' w-100 d-flex justify-content-center'>Магазин пуст. Ожидайте новые сундуки!</div>
                    }
                    {data.data.chests.map((chest, index) => {
                        return (
                            <ChestContainerItem
                                key={index} 
                                item={chest}
                            />
                        )
                    })
                    }
                </>
            }

            {isLoading &&
                <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
            }

            {isError &&
                <DataFetchError text={"Ой! Что-то пошло не так..."} />
            }

        </div>
    )
}