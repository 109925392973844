//@ts-nocheck
import './ShopHeroContainerItem.scss'

import { useMutation } from 'react-query'
import { buy } from '../../../api/shopHero'
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { decrementUserLootCountCrystal } from '../../../redux/userLootCrystalSlice/userLootCrystalSlice';
import DataFetchLoader from '../../DataFetchLoader/DataFetchLoader';
import HeroNewModal from '../../HeroNewModal/HeroNewModal';
import ToastError from '../../Toasts/ToastError/ToastError';

export default function ShopHeroContainerItem({ item }) {

    const countUserLootCrystal = useSelector((state) => state.userLootCrystal.count)
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()

    const shopHeroContainerItemBuyMutation = useMutation((heroId: number) => buy(heroId), {
        onSuccess: (data) => {

            if(data.data.isBuy === true) {
                dispatch(decrementUserLootCountCrystal(item.price))
                const myModal = new bootstrap.Modal(document.getElementById('heroNewModal' + item.hero.id))
                myModal.show()
            } else {
                ToastError(data.data.error)
            }

            setIsLoading(false)
        }
    })
    const shopHeroContainerItemBuy = () => {

        if (item.userHero !== null)
            return

        if (BigInt(countUserLootCrystal) < BigInt(item.price)) {

            const shopHeroPriceElem = document.getElementById('shopHeroPrice' + item.heroId);
            shopHeroPriceElem.classList.toggle('shop-hero-price-animate');
            setTimeout(() => {
                shopHeroPriceElem.classList.remove('shop-hero-price-animate');
            }, 300);

            return
        }

        if (isLoading)
            return

        setIsLoading(true)
        shopHeroContainerItemBuyMutation.mutate(item.heroId)
    }

    return (
        <>

            <div onClick={shopHeroContainerItemBuy} className="shop-hero-container-item d-flex justify-content-between align-items-center w-100" style={{ cursor: 'pointer' }}>
                <div className='h-100 d-flex flex-column justify-content-between' style={{ paddingRight: '8px' }}>
                    <div className='shop-hero-container-item-name'>
                        {item.hero.nameRu}
                    </div>
                    <div className='shop-hero-container-item-description'>
                        {item.hero.descriptionRu}
                    </div>
                    <div className='d-flex align-items-center'>

                        {item.lootId === 2 &&
                            <img src='/img/loot/crystal-icon.png' width={10.5} height={16} style={{ marginLeft: '8px', marginRight: '5.5px' }} />
                        }

                        <div id={"shopHeroPrice" + item.heroId} className={`shop-hero-container-item-price ${(BigInt(countUserLootCrystal) < BigInt(item.price) && !isLoading) ? 'shop-hero-container-item-price-red' : ''}`}>{item.price}</div>

                    </div>
                </div>
                <div>
                    {item.userHero === null &&
                        <div className='position-relative'>
                            {isLoading &&
                                <div className='w-100 h-100 position-absolute' style={{ zIndex: '10' }}>
                                    <DataFetchLoader width={50} height={50} color={"#B28E4C"} />
                                </div>
                            }
                            <img src={`/img/heroes/${item.hero.pathImage}.png`} width={80} height={80} style={{ filter: 'blur(5px)' }} />
                        </div>
                    }
                </div>
            </div>
            <HeroNewModal
                hero={item.hero}
                type='shop'
            />
        </>
    )
}