import './MiniNavbarButton.scss'

export default function MiniNavbarButton() {
    return (
        <div style={{cursor: 'pointer'}} className="mini-navbar-button d-flex justify-content-center align-items-center" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMiniNavbar" aria-controls="offcanvasMiniNavbar">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.2222 1H12.5555C11.5736 1 10.7777 1.79594 10.7777 2.77778V5.44445C10.7777 6.42629 11.5736 7.22222 12.5555 7.22222H15.2222C16.204 7.22222 16.9999 6.42629 16.9999 5.44445V2.77778C16.9999 1.79594 16.204 1 15.2222 1Z" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5.44445 1H2.77778C1.79594 1 1 1.79594 1 2.77778V5.44445C1 6.42629 1.79594 7.22222 2.77778 7.22222H5.44445C6.42629 7.22222 7.22223 6.42629 7.22223 5.44445V2.77778C7.22223 1.79594 6.42629 1 5.44445 1Z" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.2222 10.7777H12.5555C11.5736 10.7777 10.7777 11.5736 10.7777 12.5555V15.2222C10.7777 16.204 11.5736 16.9999 12.5555 16.9999H15.2222C16.204 16.9999 16.9999 16.204 16.9999 15.2222V12.5555C16.9999 11.5736 16.204 10.7777 15.2222 10.7777Z" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5.44445 10.7777H2.77778C1.79594 10.7777 1 11.5736 1 12.5555V15.2222C1 16.204 1.79594 16.9999 2.77778 16.9999H5.44445C6.42629 16.9999 7.22223 16.204 7.22223 15.2222V12.5555C7.22223 11.5736 6.42629 10.7777 5.44445 10.7777Z" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>
    )
}