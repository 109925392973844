//@ts-nocheck
import './ShopHeroContainer.scss'

import { useQuery } from "react-query"
import { get } from "../../api/shopHero"
import DataFetchError from '../DataFetchError/DataFetchError'
import DataFetchLoader from '../DataFetchLoader/DataFetchLoader'
import ShopHeroContainerItem from './ShopHeroContainerItem/ShopHeroContainerItem'

export default function ShopHeroContainer() {

    const { data, isLoading, isError, isSuccess } = useQuery(
        ['shopHero'],
        () => get(),
        {
            keepPreviousData: true,
        }
    )

    return (
        <div className='shop-hero-container d-flex flex-wrap justify-content-between' style={{ gap: '8px' }}>

            {isSuccess &&
                <>
                    {data.data.shopHero.length === 0 &&
                        <div className=' w-100 d-flex justify-content-center'>Магазин пуст. Ожидайте новых героев!</div>
                    }

                    {data.data.shopHero.map((shopHeroItem, index) => {
                        return (
                            <ShopHeroContainerItem
                                key={index}
                                item={shopHeroItem}
                            />
                        )
                    })
                    }
                </>
            }

            {(isLoading) &&
                <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
            }

            {isError &&
                <DataFetchError text={"Ой! Что-то пошло не так..."} />
            }

        </div>
    )
}