//@ts-nocheck

import { getInitData } from '../../../utils/telegram'
import './RatingContainerItemGold.scss'

export default function RatingContainerItemGold({ user, telegramId, place, count }) {

    const initDataId = getInitData()?.user.id

    return (
        <div className={`rating-container-item-gold ${place > 3 ? 'rating-container-item-gold-opacity' : ''} ${(initDataId !== undefined && initDataId == telegramId) ? 'rating-container-item-gold-user' : ''} w-100 d-flex justify-content-between align-items-center`}>
            <div className='rating-container-item-user d-flex align-items-center'>

                {place > 3 &&
                    <svg style={{ marginRight: '12px' }} width="28" height="32" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.817 19.43C11.2905 19.43 10.3327 18.3377 9.88818 17.7236" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M9.63756 14.4936C9.63414 13.9193 9.40314 13.3698 8.99503 12.965C8.58692 12.5602 8.03485 12.3329 7.4594 12.3329C6.88396 12.3329 6.33189 12.5602 5.92378 12.965C5.51567 13.3698 5.28467 13.9193 5.28125 14.4936" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M7.45983 25.8804C8.77156 25.9837 8.57144 29.4128 6.89555 29.2251C5.21966 29.0373 5.51028 25.7268 7.45983 25.8804Z" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M2.78134 4.3862C3.51598 3.65313 3.78668 2.7347 3.38595 2.33483C2.98523 1.93496 2.06483 2.20508 1.33019 2.93815C0.595546 3.67123 0.32485 4.58966 0.725573 4.98953C1.1263 5.38939 2.04669 5.11927 2.78134 4.3862Z" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M6.49238 6.69442C5.54432 5.18113 4.50694 3.7254 3.38574 2.33496" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M5.13693 7.99941C3.95749 6.69433 0.725586 4.98956 0.725586 4.98956" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M13.8174 19.43C16.3439 19.43 17.3018 18.3377 17.7462 17.7236" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M17.9961 14.4936C17.9995 13.9193 18.2305 13.3698 18.6386 12.965C19.0467 12.5602 19.5988 12.3329 20.1742 12.3329C20.7497 12.3329 21.3018 12.5602 21.7099 12.965C22.118 13.3698 22.349 13.9193 22.3524 14.4936" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M13.8169 30.9999C17.7457 30.9999 18.1904 29.7371 18.1904 28.918C18.1904 28.0988 17.3353 25.3571 13.8169 25.3571C10.2984 25.3571 9.44336 28.0989 9.44336 28.918C9.44336 29.737 9.888 30.9999 13.8169 30.9999Z" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M20.1744 25.8804C18.8627 25.9837 19.0628 29.4128 20.7387 29.2251C22.4146 29.0373 22.124 25.7268 20.1744 25.8804Z" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M26.9084 4.98945C27.3091 4.58958 27.0384 3.67115 26.3038 2.93808C25.5692 2.205 24.6488 1.93489 24.248 2.33475C23.8473 2.73462 24.118 3.65305 24.8527 4.38612C25.5873 5.1192 26.5077 5.38932 26.9084 4.98945Z" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M21.1421 6.69442C22.0902 5.18113 23.1275 3.7254 24.2487 2.33496" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M22.4971 7.99941C23.6765 6.69433 26.9084 4.98956 26.9084 4.98956" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M17.1566 4.42085L17.1763 4.42138C17.6499 4.12982 18.0198 3.69721 18.2338 3.18462C17.1244 3.18116 16.0275 3.41794 15.0187 3.87862C15.8791 2.97241 16.6789 2.01081 17.4129 1C17.4129 1 12.6245 2.19454 11.0055 4.25585H11.0182C4.37683 6.07 0.539551 14.0551 0.539551 18.7133C0.539551 23.992 10.2218 25.3572 13.8172 25.3572C17.4125 25.3572 27.0948 23.9923 27.0948 18.7133C27.0948 14.1832 23.4647 6.50769 17.1566 4.42085Z" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                }

                {place <= 3 &&
                    <>
                        {place === 1 &&
                            <div className='rating-container-item-place d-flex justify-content-center'>
                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5261 14.7368C14.5956 14.7368 17.8945 11.4379 17.8945 7.3684C17.8945 3.29895 14.5956 0 10.5261 0C6.45666 0 3.15771 3.29895 3.15771 7.3684C3.15771 11.4379 6.45666 14.7368 10.5261 14.7368ZM10.5261 4.21051C10.2271 4.21051 10.0271 4.56929 9.62706 5.28684L9.52359 5.47248C9.41001 5.67638 9.35317 5.77834 9.26454 5.8456C9.17591 5.91288 9.06559 5.93784 8.84485 5.98778L8.64391 6.03325C7.86713 6.209 7.47876 6.29687 7.38636 6.594C7.29396 6.89113 7.55872 7.20073 8.08826 7.81995L8.22526 7.98015C8.37573 8.15611 8.45096 8.24409 8.48486 8.35293C8.51864 8.46176 8.50728 8.57913 8.48454 8.81397L8.4638 9.02765C8.38376 9.85386 8.34374 10.2669 8.58559 10.4506C8.82759 10.6342 9.19117 10.4668 9.91843 10.132L10.1065 10.0453C10.3133 9.95018 10.4165 9.9026 10.5261 9.9026C10.6357 9.9026 10.739 9.95018 10.9457 10.0453L11.1338 10.132C11.8611 10.4668 12.2246 10.6342 12.4666 10.4506C12.7085 10.2669 12.6684 9.85386 12.5884 9.02765L12.5677 8.81397C12.5449 8.57913 12.5336 8.46176 12.5674 8.35293C12.6013 8.24409 12.6765 8.15611 12.8269 7.98016L12.964 7.81995C13.4935 7.20073 13.7583 6.89113 13.6659 6.594C13.5735 6.29687 13.1851 6.209 12.4083 6.03325L12.2074 5.98778C11.9866 5.93784 11.8763 5.91288 11.7877 5.8456C11.6991 5.77834 11.6422 5.67638 11.5286 5.47248L11.4252 5.28684C11.0252 4.56929 10.8252 4.21051 10.5261 4.21051Z" fill="#B28E4C" />
                                    <path d="M2.62648 11.5732L1.04427 13.2116C0.475722 13.8002 0.191449 14.0945 0.0930812 14.3438C-0.131076 14.9118 0.060755 15.5419 0.548817 15.8406C0.763005 15.9718 1.14933 16.0126 1.92198 16.0944C2.3582 16.1405 2.57636 16.1635 2.75904 16.2335C3.16805 16.3904 3.48621 16.7198 3.63762 17.1432C3.70525 17.3324 3.72753 17.5583 3.77209 18.01C3.85102 18.81 3.89048 19.21 4.01712 19.4317C4.30569 19.9371 4.91421 20.1357 5.46279 19.9036C5.70353 19.8017 5.9878 19.5074 6.55634 18.9188L9.16902 16.2135C6.32951 15.7814 3.92867 14.0147 2.62648 11.5732Z" fill="#B28E4C" />
                                    <path d="M11.8833 16.2135L14.496 18.9188C15.0646 19.5074 15.3488 19.8017 15.5895 19.9036C16.1381 20.1357 16.7467 19.9371 17.0352 19.4317C17.1618 19.21 17.2013 18.81 17.2802 18.01C17.3248 17.5583 17.3471 17.3324 17.4147 17.1432C17.5661 16.7198 17.8842 16.3904 18.2933 16.2335C18.476 16.1635 18.6941 16.1405 19.1303 16.0944C19.903 16.0126 20.2893 15.9718 20.5035 15.8406C20.9916 15.5419 21.1834 14.9118 20.9593 14.3438C20.8609 14.0945 20.5766 13.8002 20.008 13.2116L18.4258 11.5732C17.1236 14.0147 14.7228 15.7814 11.8833 16.2135Z" fill="#B28E4C" />
                                </svg>
                            </div>
                        }
                        {place === 2 &&
                            <div className='rating-container-item-place d-flex justify-content-center'>
                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5261 14.7368C14.5956 14.7368 17.8945 11.4379 17.8945 7.3684C17.8945 3.29895 14.5956 0 10.5261 0C6.45666 0 3.15771 3.29895 3.15771 7.3684C3.15771 11.4379 6.45666 14.7368 10.5261 14.7368ZM10.5261 4.21051C10.2271 4.21051 10.0271 4.56929 9.62706 5.28684L9.52359 5.47248C9.41001 5.67638 9.35317 5.77834 9.26454 5.8456C9.17591 5.91288 9.06559 5.93784 8.84485 5.98778L8.64391 6.03325C7.86713 6.209 7.47876 6.29687 7.38636 6.594C7.29396 6.89113 7.55872 7.20073 8.08826 7.81995L8.22526 7.98015C8.37573 8.15611 8.45096 8.24409 8.48486 8.35293C8.51864 8.46176 8.50728 8.57913 8.48454 8.81397L8.4638 9.02765C8.38376 9.85386 8.34374 10.2669 8.58559 10.4506C8.82759 10.6342 9.19117 10.4668 9.91843 10.132L10.1065 10.0453C10.3133 9.95018 10.4165 9.9026 10.5261 9.9026C10.6357 9.9026 10.739 9.95018 10.9457 10.0453L11.1338 10.132C11.8611 10.4668 12.2246 10.6342 12.4666 10.4506C12.7085 10.2669 12.6684 9.85386 12.5884 9.02765L12.5677 8.81397C12.5449 8.57913 12.5336 8.46176 12.5674 8.35293C12.6013 8.24409 12.6765 8.15611 12.8269 7.98016L12.964 7.81995C13.4935 7.20073 13.7583 6.89113 13.6659 6.594C13.5735 6.29687 13.1851 6.209 12.4083 6.03325L12.2074 5.98778C11.9866 5.93784 11.8763 5.91288 11.7877 5.8456C11.6991 5.77834 11.6422 5.67638 11.5286 5.47248L11.4252 5.28684C11.0252 4.56929 10.8252 4.21051 10.5261 4.21051Z" fill="#B0B0B0" />
                                    <path d="M2.62648 11.5732L1.04427 13.2116C0.475722 13.8002 0.191449 14.0945 0.0930812 14.3438C-0.131076 14.9118 0.060755 15.5419 0.548817 15.8406C0.763005 15.9718 1.14933 16.0126 1.92198 16.0944C2.3582 16.1405 2.57636 16.1635 2.75904 16.2335C3.16805 16.3904 3.48621 16.7198 3.63762 17.1432C3.70525 17.3324 3.72753 17.5583 3.77209 18.01C3.85102 18.81 3.89048 19.21 4.01712 19.4317C4.30569 19.9371 4.91421 20.1357 5.46279 19.9036C5.70353 19.8017 5.9878 19.5074 6.55634 18.9188L9.16902 16.2135C6.32951 15.7814 3.92867 14.0147 2.62648 11.5732Z" fill="#B0B0B0" />
                                    <path d="M11.8833 16.2135L14.496 18.9188C15.0646 19.5074 15.3488 19.8017 15.5895 19.9036C16.1381 20.1357 16.7467 19.9371 17.0352 19.4317C17.1618 19.21 17.2013 18.81 17.2802 18.01C17.3248 17.5583 17.3471 17.3324 17.4147 17.1432C17.5661 16.7198 17.8842 16.3904 18.2933 16.2335C18.476 16.1635 18.6941 16.1405 19.1303 16.0944C19.903 16.0126 20.2893 15.9718 20.5035 15.8406C20.9916 15.5419 21.1834 14.9118 20.9593 14.3438C20.8609 14.0945 20.5766 13.8002 20.008 13.2116L18.4258 11.5732C17.1236 14.0147 14.7228 15.7814 11.8833 16.2135Z" fill="#B0B0B0" />
                                </svg>

                            </div>
                        }
                        {place === 3 &&
                            <div className='rating-container-item-place d-flex justify-content-center'>
                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5261 14.7368C14.5956 14.7368 17.8945 11.4379 17.8945 7.3684C17.8945 3.29895 14.5956 0 10.5261 0C6.45666 0 3.15771 3.29895 3.15771 7.3684C3.15771 11.4379 6.45666 14.7368 10.5261 14.7368ZM10.5261 4.21051C10.2271 4.21051 10.0271 4.56929 9.62706 5.28684L9.52359 5.47248C9.41001 5.67638 9.35317 5.77834 9.26454 5.8456C9.17591 5.91288 9.06559 5.93784 8.84485 5.98778L8.64391 6.03325C7.86713 6.209 7.47876 6.29687 7.38636 6.594C7.29396 6.89113 7.55872 7.20073 8.08826 7.81995L8.22526 7.98015C8.37573 8.15611 8.45096 8.24409 8.48486 8.35293C8.51864 8.46176 8.50728 8.57913 8.48454 8.81397L8.4638 9.02765C8.38376 9.85386 8.34374 10.2669 8.58559 10.4506C8.82759 10.6342 9.19117 10.4668 9.91843 10.132L10.1065 10.0453C10.3133 9.95018 10.4165 9.9026 10.5261 9.9026C10.6357 9.9026 10.739 9.95018 10.9457 10.0453L11.1338 10.132C11.8611 10.4668 12.2246 10.6342 12.4666 10.4506C12.7085 10.2669 12.6684 9.85386 12.5884 9.02765L12.5677 8.81397C12.5449 8.57913 12.5336 8.46176 12.5674 8.35293C12.6013 8.24409 12.6765 8.15611 12.8269 7.98016L12.964 7.81995C13.4935 7.20073 13.7583 6.89113 13.6659 6.594C13.5735 6.29687 13.1851 6.209 12.4083 6.03325L12.2074 5.98778C11.9866 5.93784 11.8763 5.91288 11.7877 5.8456C11.6991 5.77834 11.6422 5.67638 11.5286 5.47248L11.4252 5.28684C11.0252 4.56929 10.8252 4.21051 10.5261 4.21051Z" fill="#6E1D1C" />
                                    <path d="M2.62648 11.5732L1.04427 13.2116C0.475722 13.8002 0.191449 14.0945 0.0930812 14.3438C-0.131076 14.9118 0.060755 15.5419 0.548817 15.8406C0.763005 15.9718 1.14933 16.0126 1.92198 16.0944C2.3582 16.1405 2.57636 16.1635 2.75904 16.2335C3.16805 16.3904 3.48621 16.7198 3.63762 17.1432C3.70525 17.3324 3.72753 17.5583 3.77209 18.01C3.85102 18.81 3.89048 19.21 4.01712 19.4317C4.30569 19.9371 4.91421 20.1357 5.46279 19.9036C5.70353 19.8017 5.9878 19.5074 6.55634 18.9188L9.16902 16.2135C6.32951 15.7814 3.92867 14.0147 2.62648 11.5732Z" fill="#6E1D1C" />
                                    <path d="M11.8833 16.2135L14.496 18.9188C15.0646 19.5074 15.3488 19.8017 15.5895 19.9036C16.1381 20.1357 16.7467 19.9371 17.0352 19.4317C17.1618 19.21 17.2013 18.81 17.2802 18.01C17.3248 17.5583 17.3471 17.3324 17.4147 17.1432C17.5661 16.7198 17.8842 16.3904 18.2933 16.2335C18.476 16.1635 18.6941 16.1405 19.1303 16.0944C19.903 16.0126 20.2893 15.9718 20.5035 15.8406C20.9916 15.5419 21.1834 14.9118 20.9593 14.3438C20.8609 14.0945 20.5766 13.8002 20.008 13.2116L18.4258 11.5732C17.1236 14.0147 14.7228 15.7814 11.8833 16.2135Z" fill="#6E1D1C" />
                                </svg>

                            </div>
                        }
                    </>
                }

                {place > 3 &&
                    (place + ". " + user)
                }

                {place <= 3 &&
                    <>
                        {user}
                    </>
                }

            </div>
            <div className='d-flex align-items-center'>
                <div className='rating-container-item-count' style={{ marginRight: '6px' }}>{count}</div>
                <img src='/img/loot/gold-icon.png' width={20} height={22} />
            </div>
        </div>
    )
}