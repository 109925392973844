//@ts-nocheck

import { NavLink } from 'react-router-dom'
import './MiniNavbar.scss'

export default function MiniNavbar() {
    return (
        <div className='mini-navbar d-flex flex-row justify-content-between position-absolute offcanvas offcanvas-end' tabindex="-1" id="offcanvasMiniNavbar" aria-labelledby="offcanvasMiniNavbarLabel" data-bs-backdrop="false" data-bs-scroll="true">
            <div className='d-flex align-items-center justify-content-center'>
                <svg width="22" height="23" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.3">
                        <path d="M7.00667 13.21C7.55954 13.21 8.00773 12.785 8.00773 12.2608C8.00773 11.7366 7.55954 11.3116 7.00667 11.3116C6.45381 11.3116 6.00562 11.7366 6.00562 12.2608C6.00562 12.785 6.45381 13.21 7.00667 13.21Z" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M7.00659 12.2607V14.1589" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M15.0155 6.69837V4.38232" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M15.0155 6.69836L13.5405 8.43231" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M12.8799 5.42639L15.0156 6.6983" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M15.0155 6.69836L16.4904 8.43231" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M17.1512 5.42639L15.0155 6.6983" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M13.0134 11.0343V16.5319C13.0134 17.2331 12.4181 17.7975 11.6785 17.7975H2.33481C1.59526 17.7975 0.999939 17.2331 0.999939 16.5319V9.57097C0.999939 8.86975 1.59526 8.3053 2.33481 8.3053H10.1352" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M3.01453 4.79701C3.01453 2.70009 4.80744 1.00012 7.01901 1.00012C9.23059 1.00012 11.0235 2.70009 11.0235 4.79701" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M11.0233 4.79712V6.06243" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M3.0144 4.48071V6.06229" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                </svg>
            </div>

            {true &&
                <NavLink className={'mini-navbar-item-nav-link h-100 position-relative'} to="/shop">
                    <div className='mini-navbar-item d-flex flex-column align-items-center'>
                        <svg className='mini-navbar-item-icon' width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.3203 8.33329C18.2894 7.24073 18.1855 6.54417 17.8355 5.94974C17.3376 5.10432 16.4411 4.63384 14.648 3.69289L12.9814 2.81827C11.5184 2.05051 10.7869 1.66663 9.99996 1.66663C9.21304 1.66663 8.48154 2.05051 7.01853 2.81827L5.35187 3.69289C3.55882 4.63384 2.66228 5.10432 2.16446 5.94974C1.66663 6.79517 1.66663 7.84718 1.66663 9.95121V10.0487C1.66663 12.1527 1.66663 13.2048 2.16446 14.0502C2.66228 14.8956 3.55882 15.366 5.35187 16.307L7.01853 17.1816C8.48154 17.9494 9.21304 18.3333 9.99996 18.3333C10.7869 18.3333 11.5184 17.9494 12.9814 17.1816L14.648 16.307C16.4411 15.366 17.3376 14.8956 17.8355 14.0502C18.1855 13.4557 18.2894 12.7592 18.3203 11.6666" stroke="#B0B0B0" strokeLinecap="round" />
                            <path d="M17.5 6.25L14.1667 7.91667M14.1667 7.91667C14.1667 7.91667 13.9127 8.04364 13.75 8.125C12.2855 8.85725 10 10 10 10M14.1667 7.91667V10.8333M14.1667 7.91667L6.25 3.75M10 10L2.5 6.25M10 10V17.9167" stroke="#B0B0B0" strokeLinecap="round" />
                        </svg>
                        <div className='mini-navbar-item-title'>
                            Игровой магазин
                        </div>
                    </div>
                </NavLink>
            }

            <NavLink className={'mini-navbar-item-nav-link h-100'} to="/rating">
                <div className='mini-navbar-item d-flex flex-column align-items-center '>
                    <svg className='mini-navbar-item-icon' width="36" height="24" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.64093 17.1801L10.4604 20.2197C10.4973 20.3372 10.4972 20.4632 10.4601 20.5807C10.4229 20.6982 10.3506 20.8014 10.2528 20.8763C10.1549 20.9512 10.0365 20.9942 9.91337 20.9995C9.79028 21.0047 9.66855 20.972 9.56471 20.9057L7.04923 19.1525C6.95556 19.0829 6.84197 19.0453 6.72527 19.0453C6.60857 19.0453 6.49497 19.0829 6.4013 19.1525L3.88583 20.9057C3.78198 20.972 3.66026 21.0047 3.53717 20.9995C3.41408 20.9942 3.29559 20.9512 3.19778 20.8763C3.09997 20.8014 3.02759 20.6982 2.99044 20.5807C2.9533 20.4632 2.9532 20.3372 2.99016 20.2197L3.8096 17.1801C3.84179 17.0635 3.83909 16.9399 3.80183 16.8248C3.76458 16.7096 3.6944 16.6079 3.59998 16.5322L1.23695 14.5789C1.13825 14.5014 1.06541 14.3958 1.02807 14.276C0.990725 14.1562 0.990642 14.0279 1.02783 13.9081C1.06502 13.7883 1.13772 13.6826 1.23632 13.605C1.33492 13.5274 1.45476 13.4816 1.57997 13.4736L4.59092 13.3497C4.71416 13.3405 4.83155 13.2934 4.92705 13.215C5.02255 13.1365 5.0915 13.0305 5.12451 12.9114L6.17262 9.94812" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M23.476 9.97668L24.5242 12.9114C24.562 13.0252 24.6331 13.125 24.7282 13.198C24.8233 13.2711 24.9381 13.3139 25.0577 13.3211L28.0687 13.445C28.1939 13.453 28.3137 13.4988 28.4123 13.5764C28.5109 13.654 28.5836 13.7597 28.6208 13.8795C28.658 13.9993 28.6579 14.1276 28.6206 14.2474C28.5832 14.3672 28.5104 14.4728 28.4117 14.5503L26.0487 16.5322C25.9543 16.6079 25.8841 16.7096 25.8468 16.8247C25.8096 16.9399 25.8069 17.0634 25.8391 17.1801L26.6585 20.2196C26.6955 20.3372 26.6954 20.4632 26.6582 20.5807C26.6211 20.6982 26.5487 20.8013 26.4509 20.8763C26.3531 20.9512 26.2346 20.9942 26.1115 20.9994C25.9884 21.0047 25.8667 20.972 25.7628 20.9057L23.2474 19.1525C23.1537 19.0829 23.0401 19.0453 22.9234 19.0453C22.8067 19.0453 22.6931 19.0829 22.5994 19.1525L20.084 20.9057C19.9801 20.972 19.8584 21.0047 19.7353 20.9994C19.6122 20.9942 19.4937 20.9512 19.3959 20.8763C19.2981 20.8013 19.2257 20.6982 19.1886 20.5807C19.1514 20.4632 19.1513 20.3372 19.1883 20.2196L19.7409 18.152L20.0077 17.1992" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M15.377 1.4012L16.4251 4.33592C16.4629 4.44969 16.534 4.54952 16.6291 4.62255C16.7242 4.69557 16.839 4.73845 16.9587 4.74564L19.9696 4.86951C20.0948 4.87748 20.2147 4.9233 20.3133 5.00089C20.4119 5.07848 20.4846 5.18419 20.5218 5.30402C20.559 5.42385 20.5589 5.55215 20.5215 5.67193C20.4842 5.79171 20.4114 5.89733 20.3127 5.97479L17.9496 7.95668C17.8552 8.03239 17.785 8.1341 17.7478 8.24925C17.7105 8.3644 17.7078 8.48794 17.74 8.60461L18.5594 11.6441C18.5964 11.7617 18.5963 11.8877 18.5592 12.0052C18.522 12.1227 18.4496 12.2259 18.3518 12.3008C18.254 12.3757 18.1355 12.4187 18.0124 12.424C17.8893 12.4292 17.7676 12.3965 17.6638 12.3302L15.1483 10.577C15.0546 10.5074 14.941 10.4698 14.8243 10.4698C14.7076 10.4698 14.594 10.5074 14.5004 10.577L11.9658 12.3302C11.862 12.3965 11.7403 12.4292 11.6172 12.424C11.4941 12.4187 11.3756 12.3757 11.2778 12.3008C11.18 12.2259 11.1076 12.1227 11.0705 12.0052C11.0333 11.8877 11.0332 11.7617 11.0702 11.6441L11.8896 8.60461C11.9218 8.48794 11.9191 8.3644 11.8818 8.24925C11.8446 8.1341 11.7744 8.03239 11.68 7.95668L9.31697 6.05102C9.19533 5.98045 9.10133 5.87059 9.05043 5.7395C8.99952 5.60841 8.99474 5.4639 9.03688 5.32974C9.07901 5.19557 9.16555 5.07974 9.28226 5.00129C9.39897 4.92284 9.5389 4.88645 9.67904 4.89809L12.69 4.77422C12.8132 4.76498 12.9306 4.71792 13.0261 4.63947C13.1216 4.56103 13.1906 4.45502 13.2236 4.33592L14.2717 1.4012C14.3097 1.28456 14.3836 1.18294 14.4829 1.11087C14.5821 1.03881 14.7017 1 14.8243 1C14.947 1 15.0665 1.03881 15.1658 1.11087C15.2651 1.18294 15.339 1.28456 15.377 1.4012Z" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <div className='mini-navbar-item-title'>
                        Рейтинговая таблица
                    </div>
                </div>
            </NavLink>

        </div>
    )
}
